import React from "react"
import {Grid, Icon, Segment} from "semantic-ui-react";
import Constants from "./Constants"

class HelpDocuments extends React.Component {

  render () {
    return (
      <>
        <Segment size={"large"}>
          <Grid columns={"2"}>
            <Grid.Row textAlign={"center"}>
              <Grid.Column>
                <a href={Constants.PATH_PREFIX + "/" + "HELP_albo fornitori FAQ intestata quarkomp.pdf"}>
                  <Icon name="file pdf outline" size={"large"}/>
                </a>
                <span>FAQ</span>
              </Grid.Column>
              <Grid.Column>
                <a href={Constants.PATH_PREFIX + "/" + "HELP_albo fornitori help on line intestata quarkomp.pdf"}>
                  <Icon name="file pdf outline" size={"large"}/>
                </a>
                <span>Help online</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    )
  }
}

export default HelpDocuments